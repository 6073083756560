<template>
  <div class="Login">

    <div class="content">
      <div class="item">
        <div class="login-tip">
          <h3 class="login-title">
            {{ $t('login_title') }}
          </h3>
          <h5 class="login-tips">
            {{ $t('login_tips') }}
          </h5>
        </div>
      </div>
      <div v-if="navbar === 0" class="navbar">
        <div class="item">
          <mt-field
              :placeholder="$t('phonePlaceholder')"
              v-model="form.phone"
              type="number"
              :v-mask="'#### #### ##'"
              :attr="{ maxlength: 10 }"
              :state="phoneState"
          ></mt-field>
        </div>
        <div class="item">
          <mt-field
              :placeholder="$t('pinPlaceholder')"
              v-model="form.password"
              type="number"
              :attr="{ maxlength: 4 }"
              :state="pinState"
          >
            <img v-if="!visible" class="visible" src="../../assets/notVisible.png" height="20px" @click="visible = true">
            <img v-else class="visible" src="../../assets/browse.png" height="20px" @click="visible = false">
          </mt-field>
        </div>
        <div class="item">
          <div class="forget-password" @click="onForgetPin">
            {{ $t('forget_password') }}
          </div>
        </div>
      </div>
      <div v-else class="navbar">
        <div class="item">
          <mt-field
              :placeholder="$t('phonePlaceholder')"
              v-model="form.phone"
              type="text"
              :state="phoneState"
          ></mt-field>
        </div>
      </div>

      <div class="submit">
        <mt-button size="large" @click="submit">{{
          $t("login")
        }}</mt-button>
      </div>
      <div class="bottom" v-if="navbar === 0">
        <div class="verification-code-login" @click="navbar = 1">
          {{ $t('verification_code_login') }}
        </div>
      </div>
      <div class="bottom" v-else>
        <div class="password-login" @click="navbar = 0">
          {{ $t('password_login') }}
        </div>
      </div>
    </div>
    <get-code ref="getCode" :phone="form.phone" type="LOGIN" @submit="getCodeRes" />
  </div>
</template>

<script>
import {phoneRules, validateCodeRules, passwordRules, pinRules} from "../../utils/rules";
import { mapState, mapMutations } from "vuex";
import getCode from '@/components/getCode.vue'
import { session } from '../../utils/api'
import { geolocation } from '../../utils/index'
import mixin from './mixin'
export default {
  name: "Login",
  mixins: [mixin],
  components: {
    getCode,
  },
  data() {
    return {
      visible: false,
      navbar: 0,
      codeType:'LOGIN'
    };
  },
  computed: {
    ...mapState(["record"]),
    phoneState() {
      this.SET_RECORD_ITEM({
        key: "loginPhone",
        value: this.form.phone,
      });
      return this.isCheck ? phoneRules(this.form.phone) : null;
    },
    validateCodeState() {
      return this.isCheck ? validateCodeRules(this.form.validateCode) : null;
    },
    passwordState() {
      this.SET_RECORD_ITEM({
        key: "loginPassword",
        value: this.form.password,
      });
      return this.isCheck ? passwordRules(this.form.password) : null;
    },
    pinState() {
      this.SET_RECORD_ITEM({
        key: "loginPassword",
        value: this.form.password,
      });
      return this.isCheck ? pinRules(this.form.password) : null;
    },
  },
  created() {
    this.form.phone = this.record.loginPhone || "";
    this.form.password = this.record.loginPassword || "";
  },
  methods: {
    ...mapMutations(["SET_RECORD_ITEM", "SET_ACCESS_TOKEN"]),
    onRegister() {
      this.SET_RECORD_ITEM({
        key: "registerPhone",
        value: this.form.phone,
      });
      this.$router.push('/user/register');
    },
    onForgetPin(){
      this.SET_RECORD_ITEM({
        key: "passwordPhone",
        value: this.form.phone,
      });
      this.$router.push('/user/modifyPin');
    },
    async submit() {
      this.isCheck = true;
      var error = this.validation(this.navbar === 0? ['phone', 'pin']: ['phone']);
      if (error) return this.$toast(error);
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start();
      await this.doThingsByType();
      this.$indicator.close();
      this.$NProgress.done();
    },
    async doThingsByType(){
      // 如果是账号密码登录
      if (this.navbar === 0) {
        await this.login();
      }
      else { // 如果是验证码登录
        // 记录手机号信息并跳转页面
        this.$refs.getCode.popup(true)
      }
    },
    async login(){
      await this.$axios({
        method: "post",
        url: session,
        data: {
          loginType: this.navbar == 0? 'PIN_LOGIN': 'CODE_LOGIN',
          phone: this.form.phone.replace(/\s/g, ""),
          pin: this.form.password,
          code: this.form.validateCode,
          deviceId: "H5",
          device: "H5",
          isValidateCode: 'Y',
          gps: await geolocation()
        },
      })
          .then(async (e) => {
            if (e.status.code == "000") {
              this.SET_ACCESS_TOKEN(e.body.accessToken);
              this.$refs.getCode.popup(false)
              await this.getUser();
            }else if (e.status.code == "993"){
              this.$toast(e.status.msg)
              this.$refs.getCode.popup(false)
              this.onRegister()
            }else {
              this.$toast(e.status.msg);
            }
          })
          .catch(() => {});
    },
    async getCodeRes(data){
      this.form.validateCode = data.validateCode;
      await this.login();
    }
  },
};
</script>

<style scoped lang="scss">
.Login {
  position: relative;
  min-height: 100vh;
  background-image: url('../../assets/login_bg.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  .forget-password {
    text-align: right;
    color: #855ef4;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 14px;
  }
  .content {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    padding-right: 5vw;
    padding-left: 5vw;
    .item {
      margin-top: 25px;
      .mint-button{
        height:55px;
        border-radius: 50px;
        font-weight: 600;
        background: linear-gradient(#BB97FA, #855EF4);
        color: #FFFFFF;
        border:1px solid #FFFFFF;
      }
      .mint-cell {
        background-color: #f3effe;
        border-radius: 50px;
        /deep/ .mint-cell-wrapper {
          background: none;

        }
        /deep/ input {
          background-color: #f3effe;
        }
        /deep/ .mint-field-core {
          background-color: #f3effe;
          text-align: center;
          caret-color: #855ef4;
          color: #a5a4a6;
          font-size: 20px;
        }
        /deep/ input::placeholder{
          color: #999999;
          font-size: 16px;
        }
      }
      .login-tip{
        text-align: center;
        .login-title{
          font-size: 20pt;
        }
        .login-tips{
          font-size: 12pt;
        }
      }
      .title {
        margin: 6px 15px;
        font-size: 12px;
        color: #1C252A;
      }
    }
    .navbar{
      margin-bottom: 40px;
    }
    .ivr {
      color: white;
      margin: 10px 15px 0px 15px;
      .mint-button {
        color: white;
        border-color: white;
      }
    }
    .submit {
      padding: 20px 0px 0px 0px;
      .mint-button{
        height:55px;
        border-radius: 50px;
        font-weight: 600;
        background: linear-gradient(#BB97FA, #855EF4);
        color: #FFFFFF;
        border:1px solid #FFFFFF;
      }
    }
  }
  .separation {
    position: relative;
    text-align: center;
    color: white;
    margin: 0 15px;
    &::after, &::before {
      content: '';
      position: absolute;
      height: 1px;
      background-color: white;
      top: 50%;
      width: 40%;
      transform: translateY(-50%);
    }
    &::after {
      left: 0px;
    }
    &::before {
      right: 0px;
    }
  }
  .visible {
    margin-left: 5px;
  }
  .bottom {
    display: flex;
    padding-bottom: 20px;
    font-size: 14px;
    div {
      flex: 1;
      text-align: center;
    }
    .verification-code-login,.password-login{
      margin-top: 15px;
    }
  }
}
</style>
